export const urls = {
  applications: '/app-edge/applications',
  addApplication: '/app-edge/applications/add',
  applicationGroups: '/app-edge/application-groups',
  addApplicationGroups: '/app-edge/application-groups/add',
  editApplication: '/app-edge/applications/edit',
  security: '/app-edge/security',
  addRule: '/app-edge/security/add-rule',
  apiDiscovery: '/app-edge/api-discovery',
};
